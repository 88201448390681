body, html {
    text-align: center;
    color: black;
}

.background {
    background-color: black;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;

    background-image: url("../public/Belle-Epoque-Logo.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}

.menu-bar {
    color: white;
    height: 50px;
    /*background-color: white;*/
    border-bottom: 1px white solid;
    /*padding-bottom: 15px;*/
    margin-bottom: 15px;
}

.filter-chip {
    margin-left: 10px;
    /*Doesn't work*/
    color: white;
}

.table-dialog-close {
    display: inline;
    position: absolute;
    height: 50px;
    width: 50px;
    right: 0;
    top: 0;
}

.table-dialog-close button {
    height: 50px;
    width: 50px;
    color: #8a38ad;
}

.table-dialog-info {
    height: 200px;
    text-align: center;
    border-bottom: 1px solid #f58f4e;
}

.table-dialog-buttons {
}

.table-dialog-status-buttons {
    width: 150px;
    color: black;
}

.table-dialog-quick-action {
    /*height: 150px;*/
    /*width: 150px;*/
    height: 100%;
    width: 85%;
}

.table-dialog-div {
    height: 400px;
    width: 500px;
    padding: 20px;
    background-color: rgba(40, 44, 52, 0.11);
}

.App {
    text-align: center;

    /*TODO Do not do this.*/
    width: 99%;
}

.table-card {
    padding: 0 5px 5px 5px;
    margin: auto;
}

.table-card-header-number {
    font-size: 40px;
    font-weight: 500;
}

.table-card-footer div:nth-last-child(n) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-card-footer div:nth-last-child(n) svg {
    margin-right: 3px;
}

.table-card-footer div:nth-last-child(-n+1) {
    border-left: 1px solid black;
}

.card-title .container-fluid {
    /*background-color: black;*/
    justify-content: space-between;
}

.card-seat-num {
    font-size: 15px;
    text-align: end;
}

.table-card-status {
    text-align: center;
    font-weight: bold;
}

.card-title {
    border-bottom: 1px solid #282c34;
}

.tables-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 20px;
    /*margin-top: 15px;*/
}